<template>
    <div class="page-layout ml-2 mr-3"> 
        <div class="pb-10 h-100">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div >
                        <span class="pointer" @click="goToListView">Employee</span>
                        <div v-if="basicInformation">Basic Information</div>
                        <div v-if="productPermission">Product Permission</div>
                    </div>
                </div> 
                <div>
                    <div v-if="basicInformation">
                        <button class="btn btn-icon pointer ml-2 text-capitalize" :disabled="edit_enable" @click="enableEdit" style="padding: 2px 6px !important;background: #00448b;border-radius: 3px !important;">
                            <i class="icon icon-pencil pointer fs-16" style="color: #fff !important;"></i>
                            <!-- <img src="../../assets/Icon material-edit.svg" height="12px;" style="margin-top: -3px;"> -->
                            <span class="mx-1 mt-1" style="color: #fff;font-size: 14px;font-weight: 600;">Edit</span>
                        </button>
                    </div>
                </div>
            </div>
            
            <div class="profile-block">
                <div class="profile-block-container pb-3"
                    style="border-radius: 15px;">
                    <div class="center-section w-100"
                        style="background-color:transparent !important;border:none !important;">
                        <div class="center-content">
                            <div class="d-flex align-items-center py-2">
                                <div class='d-flex align-items-center justify-content-center text-gray emp-tab-switch pr-3 pb-1 pt-3 pointer ml-3 pl-0'
                                    :class="{'activeTab':toShow == 'basicInfo','new-blue':toShow == 'basicInfo'}"
                                    @click="changeEditModelView('editBasicInfoView')">
                                    Basic Info
                                </div>
                                <div class='d-flex align-items-center justify-content-center text-gray emp-tab-switch pl-3 pr-0 pb-1 pt-3 pointer'
                                    :class="{'activeTab':toShow == 'productPermission','new-blue':toShow == 'productPermission'}"
                                    @click="changeEditModelView('editProductPermissionView')">
                                    Product Permission
                                </div>
                            </div>
                            <div class="preview-elements pt-3 pb-8">
                                <div class="row" v-if="ajax_call_in_progress">
                                    <div class="col-lg-12 py-5 px-3 mt-3">
                                        <div class="flex-container">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="tab-content profile-tab-content scroll-media" id="myTabContent"
                                    style="max-height: 70em;">
                                    <div class="" id="basictab" aria-labelledby="basic-tab" v-if="basicInformation">
                                        <employee-basic-info-view :comp_data="comp_data" :emp_data="emp_data" @updateSuccess="updateSuccess">
                                        </employee-basic-info-view>
                                    </div>
                                  
                                    <div class="" id="producttab" aria-labelledby="product-tab"
                                        v-else-if="productPermission">
                                       <permission-product :comp_data="comp_data" :emp_data="emp_data" @updateProductPermission="updateProductPermission"></permission-product>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
     import { EventBus } from "../eventBus/nav-bar-event.js";
    import EmployeeBasicInfoView from "./EmployeeBasicInfoView";
    import {HalfCircleSpinner} from 'epic-spinners';
    import PermissionProduct from "./PermissionProduct";
    import companies from '../mixins/companies';
    import axios from 'axios';
    import globals from '../globals';
    export default {
        data() {
            return {
                userProfile: "https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg",
                basicInformation: true,
                productPermission: false,
                toShow: "basicInfo",
                ajax_call_in_progress: false,
                emp_data: [],
                edit_enable: false,
                comp_data: {}
            };
        },
        mixins: [companies],
        components: {
            EmployeeBasicInfoView,
            PermissionProduct,
            HalfCircleSpinner
        },
        beforeRouteLeave (to, from, next) {
            if (this.edit_enable == true) {
                let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
        methods: {
            enableEdit(){
                this.edit_enable = true;
                EventBus.$emit('enableEdit', this.edit_enable);
            },
            goToListView() {
                this.$router.push('/employee/listemployee/cmpid/'+ this.$route.params.company_id);
            },
            updateSuccess() {
                this.getEmpData();
            },
            updateProductPermission() {
                this.getEmpData();
            },
            changeEditModelView(editName) {
                if (editName == "editBasicInfoView") {
                    this.toShow = 'basicInfo';
                    this.basicInformation = true;
                    this.productPermission= false;
                } else if (editName == "editProductPermissionView") {
                    this.toShow = 'productPermission';
                    this.basicInformation= false;
                    this.productPermission= true;
                } 
            },
            updateTab(data) {
                if (data == 'basicInfo') {
                    this.basicInformation = true;
                    this.productPermission= false;
                } else if (data == 'productPermission') {
                    this.basicInformation= false;
                    this.productPermission= true;
                } 
            },
            uploadCoverAttachment(e) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    this.getResumableURLprofile(input.files[0], attachment, "cover_pic");
                }
            },
            // User Profile Image Change Function
            uploadProfileAttachment(e) {
                var input = event.target;
                if (input.files && input.files[0]) {
                    const CancelToken = axios.CancelToken;
                    const source = CancelToken.source();
                    let attachment = {
                        name: input.files[0].name,
                        size: JSON.stringify(input.files[0].size),
                        source: source,
                        progress: 0,
                        created_time: new Date(),
                        error: "",
                    };
                    this.getResumableURLprofile(input.files[0], attachment, "profile_pic");
                }
            },
            getResumableURLprofile(file, attachment, type) {
                let data = {
                    file: attachment.name,
                };
                data = new FormData();
                data.append("file", file);
                this.$http
                    .post("https://cloudapis.digicollect.com/external/upload_file", data)
                    .then(response => {
                        if (response.data.status_id == 1) {
                            this.updateInfo(type, response.data.url);
                        }
                    })
                    .catch(error => {
                        // this.error = "Error Uploading"
                    });
            },
            async getEmpData() {
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getEmployeesById(this.$route.params.id)
                    if(response.status_id == 1) {
                        this.emp_data = response.response;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    console.log(err);
                }
            },
            async updateInfo(type, url) {
                let params = {
                };
                if (type == 'cover_pic') {
                    params.cover_pic = url;
                    await axios.patch(globals.AUTH_SERVICE + `/employees/${this.$route.params.id}`, params).then((response) => {
                        if(response.data.status_id == 1) {
                            this.emp_data.cover_pic = url;
                        }
                    }).catch((error) => {
                        // console.log(error);
                    });
                } else if (type == 'profile_pic') {
                    params.profile_pic = url;
                    await axios.patch(globals.AUTH_SERVICE + `/employees/${this.$route.params.id}`, params).then((response) => {
                        if(response.data.status_id == 1) {
                            this.emp_data.profile_pic = url;
                        }
                    }).catch((error) => {
                        // console.log(error);
                    });
                }
            },
            async getCompData() {
                try {
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if (response.status_id == 1) {
                        let res_data = response.response
                        this.comp_data = res_data
                    }
                }
                catch(err) {
                    // console.log(err)
                }
            },
        },
        created() {
        },
        mounted() {
            EventBus.$on('edit_enabled', () => {
                this.edit_enable = false
            })
            this.getEmpData();
            this.getCompData();
        },
        watch: {
        },
        // Adding Store Permissions
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            }
        },
    };
</script>
<style scoped>
    .emp-tab-switch {
        border-bottom: 2px solid #707070;
    }
    .emp-tab-switch.activeTab {
        border-color: #00448b;
        font-weight: 600;
    }
</style>